<template>
  <div ref="container" class="tips wrapper p-xxxl">
    <div class="tips__inner">
      <div class="tips__text">
        <h2
          class="tips__text__title"
          :class="`title-${content.titleColor}`"
          v-html="content.title"
        ></h2>
        <div
          v-if="content.text"
          class="tips__text__content"
          v-html="content.text"
        ></div>

        <Action
          v-if="content.link"
          class="btn cta"
          :content="{
            ...content.link,
            modifiers: ['btn'],
          }"
        />
      </div>
      <ul class="tips__list">
        <li
          v-for="(item, index) in content.items"
          :key="index"
          class="tips__list__el"
          :class="{ 'is-open': actif === index }"
        >
          <button class="tips__trigger" @click="openContent(index)">
            <span v-html="item.title"></span>
            <div v-if="item.count" class="tips__list__el__count">
              {{ item.count }}
            </div>

            <div class="tips__list__el__plus">
              <div></div>
              <div></div>
            </div>
          </button>

          <div ref="wrapper" class="tips__list__el__content">
            <div
              v-if="item.text"
              class="tips__list__el__content__text"
              v-html="item.text"
            ></div>

            <ul v-if="item.links" class="tips__list__el__content__links">
              <li
                v-for="(link, key) in item.links"
                :key="key"
                class="tips__list__el__content__link"
              >
                <a :href="link.url" :target="link.target">
                  {{ link.title }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ref } from 'vue'

import { useResourceStore } from '@/stores/resource'
import { push, stripHTML } from '@/utils/tracking'

import type { PropType } from 'vue'
import type { Uri } from '@/types'

export interface Tips {
  title: string
  titleColor?: string
  text: string
  link: Uri
  items: {
    title: string
    count: number
    text: string
    links: {
      target: string
      title: string
      url: string
    }[]
  }[]
}

const props = defineProps({
  content: {
    type: Object as PropType<Tips>,
    required: true,
  },
})

const container = ref<Element | null>(null)
const wrapper = ref()
const actif = ref<number>()
const resource = useResourceStore()

const openContent = (index: number) => {
  if (index === actif.value) {
    close()
    actif.value = undefined
  } else {
    close()

    gsap.to(wrapper.value[index], {
      height: 'auto',
    })

    actif.value = index
  }

  // Track click
  push({
    event: 'FAQ',
    eventCategory: 'FAQ',
    eventAction: resource.template,
    eventLabel: stripHTML(props.content.title),
  })
}

const close = () => {
  gsap.to(wrapper.value || {}, {
    ease: 'power3.inOut',
    height: 0,
  })
}
</script>

<style lang="scss" scoped>
:host {
  &:last-child {
    margin-bottom: 6rem;

    @include mq(m) {
      margin-bottom: 8rem;
    }

    @include mq(xl) {
      margin-bottom: 16rem;
    }
  }
}

.tips {
  position: relative;

  .cta {
    margin-top: 3rem;
  }
}

.tips__text__title {
  @include section-title;
}

.tips__inner {
  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.tips__trigger {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  font-weight: 500;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  gap: 2rem;

  @include mq(l) {
    padding: 4rem;
  }
}

.tips__text {
  flex-basis: 30.44%;
}

.tips__text__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}

.tips__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-basis: 61.1%;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq($until: l) {
    margin-top: 6rem;
  }
}

.tips__list__el {
  position: relative;
  flex-basis: 100%;
  border: 1px solid $c-grey-light;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $c-gray-lightest;
  }
}

.tips__list__el__count {
  @extend %fw-semi;

  position: relative;
  top: -1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6em;
  color: $c-white;
  font-size: 0.625em;
  line-height: 1.6em;
  background: $c-red;
  border-radius: 50%;

  @include mq(l) {
    font-size: 1.5rem;
  }
}

.tips__list__el__plus {
  position: relative;
  min-width: 2.4rem;
  min-height: 2.4rem;
  margin-left: auto;
  background-color: $c-cobalt;
  border-radius: 50%;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.6rem;
    height: 0.1rem;
    background: rgba($c-white, 1);
    transition: transform 0.4s ease-in-out;
    transform-origin: center center;
    transform: translate(-50%, -50%);

    &:last-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    .is-open &:last-child {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    @include mq(m) {
      width: 1.3rem;
      height: 0.2rem;
    }
  }

  @include mq(m) {
    min-width: 3rem;
    min-height: 3rem;
  }
}

.tips__list__el__content {
  overflow: hidden;
  width: calc(100% - 4rem);
  height: 0;
  margin: auto;
  margin-top: 0;
  font-size: 1.3rem;

  > *:last-child {
    margin-bottom: 3rem;
  }

  :deep(a) {
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 !important;
    font-size: 1.6rem;
    text-decoration: underline;
    transition: opacity 0.4s ease-out;

    &:hover {
      opacity: 0.5;
    }
  }

  @include mq(l) {
    width: calc(100% - 8rem);
    font-size: 1.4rem;
  }
}

.tips__list__el__content__links {
  @extend %list-nostyle;
}

.tips__list__el__content__link {
  margin-bottom: 0;

  & + & {
    margin-top: $spacing;
  }
}

.tips__link {
  width: 100%;
}

.tips__list__el__content__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  :deep(a) {
    display: block;
    color: $c-cobalt;
  }
}
</style>
